/* vendors */
// eslint-disable-next-line no-unused-vars
import svgxuse from 'svgxuse';

/* modules */
import {BackToTop} from './modules/back-to-top';
new BackToTop();

import {LazyLoading} from './modules/lazy-loading';
new LazyLoading();

// eslint-disable-next-line no-unused-vars
import style from '../scss/sound-session.scss';
