/**
 * BackToTop - handling visibility of a back-to-top button
 */
export class BackToTop {
  /**
   * constructor - set up class vars call init() function
   */
  constructor() {
    this.selector = '.back-to-top';
    this.headerSelector = 'header';
    this.modifierClass = `${this.selector.substr(1)}--hidden`;
    this.button = document.querySelector(this.selector);
    this.buttonVisible = false;
    this.scrollTimeout = null;
    this.lastScrollPosition = 0;
    this.header = document.querySelector(this.headerSelector);

    this.init();
  }

  /**
   * init() - check for button' existance and set up scrol listener
   */
  init() {
    if (this.button) {
      window.addEventListener('scroll', () => {
        window.clearTimeout(this.scrollTimeout);
        this.scrollTimeout = window.setTimeout(() => this.handleScroll(), 200);
      });
      this.button.addEventListener('click', () => {
        this.header.scrollIntoView({behavior: 'smooth'});
      });
    }
  }

  /**
   * handlescroll - callback from window.onscroll listener
   */
  handleScroll() {
    const scrollY = (window.pageYOffset || window.scrollY) | 0;

    if (scrollY >= this.lastScrollPosition || scrollY === 0) {
      if (this.buttonVisible) {
        this.button.classList.add(this.modifierClass);
        this.buttonVisible = false;
      }
    } else {
      if (!this.buttonVisible) {
        this.button.classList.remove(this.modifierClass);
        this.buttonVisible = true;
      }
    }
    this.lastScrollPosition = scrollY;
  }
}
