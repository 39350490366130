import lozad from 'lozad';

/**
 * LazyLoading - initialize lozad library
 */
export class LazyLoading {
  /**
   * constructor - do all the magic
   * @param {Object} options - overwrite default options by providing
   *                           this option Object
   */
  constructor(options = {}) {
    const {
      lazySelector = '.lazy',
    } = options;

    // start `img` lazyloader
    const observer = lozad(lazySelector);
    observer.observe();
  }
}
